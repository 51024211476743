import {IconSizeProps} from "./icons.interface.js"

{/** @todo: all icons */}
export function LinuxIcon(props: IconSizeProps){
    return (
        <div>
            <div className='flex gap-x-2 sm:gap-x-3 sm:hidden'>
                <a href='https://fedoraproject.org/' target="_blank" rel="noopener noreferrer" title="Fedora">
                    {/* License - https://iconscout.com/free-icon/fedora by: Icon Mafia*/}
                    <svg xmlns="http://www.w3.org/2000/svg" width={props.size_min} height={props.size_min} viewBox="0 0 256 256" id="fedora"><path fill="#294172" d="M256 128.004C256 57.31 198.691 0 127.998 0 57.336 0 .05 57.262 0 127.914v99.054c.038 16.042 13.049 29.029 29.101 29.029h98.949C198.72 255.969 256 198.679 256 128.004"></path><path fill="#3C6EB4" d="M165.58 30.307c-33.109 0-60.045 26.935-60.045 60.045v31.87H73.797c-33.109 0-60.045 26.937-60.045 60.046 0 33.108 26.936 60.045 60.045 60.045s60.045-26.937 60.045-60.045v-31.871h31.738c33.109 0 60.046-26.936 60.046-60.045 0-33.11-26.937-60.045-60.046-60.045zm-59.823 151.961c0 17.622-14.337 31.959-31.96 31.959s-31.96-14.337-31.96-31.959c0-17.623 14.337-31.96 31.96-31.96h31.738v.089h.222v31.871zm59.823-59.956h-31.738v-.09h-.221v-31.87c0-17.623 14.337-31.96 31.959-31.96s31.96 14.337 31.96 31.96-14.338 31.96-31.96 31.96z"></path><path fill="#FFF" d="M178.851 32.128c-4.66-1.218-8.238-1.786-13.271-1.786-33.177 0-60.075 26.899-60.075 60.074v31.842h-25.16c-7.845 0-14.185 6.165-14.18 13.996 0 7.782 6.27 13.973 14.032 13.973l20.831.004c2.473 0 4.479 2 4.479 4.469v27.553c-.031 17.491-14.219 31.659-31.71 31.659-5.925 0-7.392-.776-11.437-.776-8.497 0-14.182 5.696-14.182 13.528.002 6.479 5.554 12.049 12.348 13.827 4.66 1.218 8.238 1.787 13.271 1.787 33.177 0 60.075-26.899 60.075-60.075v-31.841h25.16c7.845 0 14.185-6.165 14.18-13.996 0-7.783-6.27-13.973-14.032-13.973l-20.831-.004a4.475 4.475 0 0 1-4.479-4.47V90.366c.031-17.491 14.219-31.659 31.71-31.659 5.925 0 7.392.777 11.437.777 8.497 0 14.182-5.697 14.182-13.528-.002-6.48-5.554-12.05-12.348-13.828"></path></svg>
                </a>
            </div>
            <div className='flex gap-x-2 sm:gap-x-3 hidden sm:flex'>
                <a href='https://fedoraproject.org/' target="_blank" rel="noopener noreferrer" title="Fedora">
                    {/* License - https://iconscout.com/free-icon/fedora by: Icon Mafia*/}
                    <svg xmlns="http://www.w3.org/2000/svg" width={props.size_standard} height={props.size_standard} viewBox="0 0 256 256" id="fedora"><path fill="#294172" d="M256 128.004C256 57.31 198.691 0 127.998 0 57.336 0 .05 57.262 0 127.914v99.054c.038 16.042 13.049 29.029 29.101 29.029h98.949C198.72 255.969 256 198.679 256 128.004"></path><path fill="#3C6EB4" d="M165.58 30.307c-33.109 0-60.045 26.935-60.045 60.045v31.87H73.797c-33.109 0-60.045 26.937-60.045 60.046 0 33.108 26.936 60.045 60.045 60.045s60.045-26.937 60.045-60.045v-31.871h31.738c33.109 0 60.046-26.936 60.046-60.045 0-33.11-26.937-60.045-60.046-60.045zm-59.823 151.961c0 17.622-14.337 31.959-31.96 31.959s-31.96-14.337-31.96-31.959c0-17.623 14.337-31.96 31.96-31.96h31.738v.089h.222v31.871zm59.823-59.956h-31.738v-.09h-.221v-31.87c0-17.623 14.337-31.96 31.959-31.96s31.96 14.337 31.96 31.96-14.338 31.96-31.96 31.96z"></path><path fill="#FFF" d="M178.851 32.128c-4.66-1.218-8.238-1.786-13.271-1.786-33.177 0-60.075 26.899-60.075 60.074v31.842h-25.16c-7.845 0-14.185 6.165-14.18 13.996 0 7.782 6.27 13.973 14.032 13.973l20.831.004c2.473 0 4.479 2 4.479 4.469v27.553c-.031 17.491-14.219 31.659-31.71 31.659-5.925 0-7.392-.776-11.437-.776-8.497 0-14.182 5.696-14.182 13.528.002 6.479 5.554 12.049 12.348 13.827 4.66 1.218 8.238 1.787 13.271 1.787 33.177 0 60.075-26.899 60.075-60.075v-31.841h25.16c7.845 0 14.185-6.165 14.18-13.996 0-7.783-6.27-13.973-14.032-13.973l-20.831-.004a4.475 4.475 0 0 1-4.479-4.47V90.366c.031-17.491 14.219-31.659 31.71-31.659 5.925 0 7.392.777 11.437.777 8.497 0 14.182-5.697 14.182-13.528-.002-6.48-5.554-12.05-12.348-13.828"></path></svg>
                </a>
            </div>
        </div>
    )
}


export function WinIcon(props: IconSizeProps){
    return (
        <div>
            <div className='flex gap-x-2 sm:gap-x-3 sm:hidden' title="Windows">
                {/* License - https://icons8.com */}
                <svg xmlns="http://www.w3.org/2000/svg" width={props.size_min} height={props.size_min} viewBox="0 0 48 48"><path fill="#00b0ff" d="M20 25.026L5.011 25 5.012 37.744 20 39.818zM22 25.03L22 40.095 42.995 43 43 25.066zM20 8.256L5 10.38 5.014 23 20 23zM22 7.973L22 23 42.995 23 42.995 5z"></path></svg>
            </div>
            <div className='flex gap-x-2 sm:gap-x-3 hidden sm:flex' title="Windows">
                {/* License - https://icons8.com */}
                <svg xmlns="http://www.w3.org/2000/svg" width={props.size_standard} height={props.size_standard} viewBox="0 0 48 48"><path fill="#00b0ff" d="M20 25.026L5.011 25 5.012 37.744 20 39.818zM22 25.03L22 40.095 42.995 43 43 25.066zM20 8.256L5 10.38 5.014 23 20 23zM22 7.973L22 23 42.995 23 42.995 5z"></path></svg>
            </div>
        </div>
    )
}


export function AndroidIcon(props: IconSizeProps){
    return (
        <div>
            <div className='flex gap-x-2 sm:gap-x-3 sm:hidden' title="Android">
                {/* License - https://icons8.com */}
                <svg xmlns="https://www.w3.org/2000/svg" width={props.size_min} height={props.size_min} viewBox="0 0 48 48">
                    <path fill="#7cb342" d="M12 29c0 1.1-.9 2-2 2s-2-.9-2-2v-9c0-1.1.9-2 2-2s2 .9 2 2V29zM40 29c0 1.1-.9 2-2 2s-2-.9-2-2v-9c0-1.1.9-2 2-2s2 .9 2 2V29zM22 40c0 1.1-.9 2-2 2s-2-.9-2-2v-9c0-1.1.9-2 2-2s2 .9 2 2V40zM30 40c0 1.1-.9 2-2 2s-2-.9-2-2v-9c0-1.1.9-2 2-2s2 .9 2 2V40z"></path><path fill="#7cb342" d="M14 18v15c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V18H14zM24 8c-6 0-9.7 3.6-10 8h20C33.7 11.6 30 8 24 8zM20 13.6c-.6 0-1-.4-1-1 0-.6.4-1 1-1s1 .4 1 1C21 13.1 20.6 13.6 20 13.6zM28 13.6c-.6 0-1-.4-1-1 0-.6.4-1 1-1s1 .4 1 1C29 13.1 28.6 13.6 28 13.6z"></path><path fill="#7cb342" d="M28.3 10.5c-.2 0-.4-.1-.6-.2-.5-.3-.6-.9-.3-1.4l1.7-2.5c.3-.5.9-.6 1.4-.3.5.3.6.9.3 1.4l-1.7 2.5C29 10.3 28.7 10.5 28.3 10.5zM19.3 10.1c-.3 0-.7-.2-.8-.5l-1.3-2.1c-.3-.5-.2-1.1.3-1.4.5-.3 1.1-.2 1.4.3l1.3 2.1c.3.5.2 1.1-.3 1.4C19.7 10 19.5 10.1 19.3 10.1z">                       
                    </path>
                </svg>
            </div>
            <div className='flex gap-x-2 sm:gap-x-3 hidden sm:flex' title="Android">
                {/* License - https://icons8.com */}
                <svg xmlns="https://www.w3.org/2000/svg" width={props.size_standard} height={props.size_standard} viewBox="0 0 48 48">
                    <path fill="#7cb342" d="M12 29c0 1.1-.9 2-2 2s-2-.9-2-2v-9c0-1.1.9-2 2-2s2 .9 2 2V29zM40 29c0 1.1-.9 2-2 2s-2-.9-2-2v-9c0-1.1.9-2 2-2s2 .9 2 2V29zM22 40c0 1.1-.9 2-2 2s-2-.9-2-2v-9c0-1.1.9-2 2-2s2 .9 2 2V40zM30 40c0 1.1-.9 2-2 2s-2-.9-2-2v-9c0-1.1.9-2 2-2s2 .9 2 2V40z"></path><path fill="#7cb342" d="M14 18v15c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V18H14zM24 8c-6 0-9.7 3.6-10 8h20C33.7 11.6 30 8 24 8zM20 13.6c-.6 0-1-.4-1-1 0-.6.4-1 1-1s1 .4 1 1C21 13.1 20.6 13.6 20 13.6zM28 13.6c-.6 0-1-.4-1-1 0-.6.4-1 1-1s1 .4 1 1C29 13.1 28.6 13.6 28 13.6z"></path><path fill="#7cb342" d="M28.3 10.5c-.2 0-.4-.1-.6-.2-.5-.3-.6-.9-.3-1.4l1.7-2.5c.3-.5.9-.6 1.4-.3.5.3.6.9.3 1.4l-1.7 2.5C29 10.3 28.7 10.5 28.3 10.5zM19.3 10.1c-.3 0-.7-.2-.8-.5l-1.3-2.1c-.3-.5-.2-1.1.3-1.4.5-.3 1.1-.2 1.4.3l1.3 2.1c.3.5.2 1.1-.3 1.4C19.7 10 19.5 10.1 19.3 10.1z">
                    </path>
                </svg>
            </div>
        </div>
    )
}


export function TwoFactor(props: IconSizeProps){
    return (
        <div>
            <div className='flex gap-x-2 sm:gap-x-3 sm:hidden' title="2FA">
                {/* License - https://iconscout.com/free-icon/password-5579228 by: Kazi Aftabur Rahman on IconScout*/}
                <svg xmlns="http://www.w3.org/2000/svg" width={props.size_min} height={props.size_min} viewBox="16 0 64 64" id="password"><path fill="#ffffff" d="M28.1 7c-.6 0-1 .4-1 1s.4 1 1 1h7.8c.6 0 1-.4 1-1s-.4-1-1-1h-7.8zm5.8 50c.6 0 1-.4 1-1s-.4-1-1-1H30c-.6 0-1 .4-1 1s.4 1 1 1h3.9z"></path><path fill="#ffffff" d="M48 36c-.6 0-1 .4-1 1v18.1c0 2.1-1.6 3.9-3.8 3.9H21.8c-2.1 0-3.8-1.8-3.8-3.9V8.9C18 6.8 19.6 5 21.8 5h21.5C45.4 5 47 6.8 47 8.9V19c0 .6.4 1 1 1s1-.4 1-1V8.9C49 5.7 46.5 3 43.2 3H21.8C18.5 3 16 5.7 16 8.9v46.2c0 3.2 2.5 5.9 5.8 5.9h21.5c3.2 0 5.8-2.7 5.8-5.9V37c-.1-.6-.5-1-1.1-1z"></path><path fill="#ffffff" d="M53 23H25c-.6 0-1 .4-1 1v8c0 .6.4 1 1 1h28c.6 0 1-.4 1-1v-8c0-.6-.4-1-1-1zm-1 8H26v-6h26v6z"></path><path fill="#ffffff" d="M28.3 29.7c.4.4 1 .4 1.4 0l.3-.3.3.3c.4.4 1 .4 1.4 0 .4-.4.4-1 0-1.4l-.3-.3.3-.3c.4-.4.4-1 0-1.4-.4-.4-1-.4-1.4 0l-.3.3-.3-.3c-.4-.4-1-.4-1.4 0-.4.4-.4 1 0 1.4l.3.3-.3.3c-.4.4-.4 1 0 1.4zm6 0c.4.4 1 .4 1.4 0l.3-.3.3.3c.4.4 1 .4 1.4 0 .4-.4.4-1 0-1.4l-.3-.3.3-.3c.4-.4.4-1 0-1.4-.4-.4-1-.4-1.4 0l-.3.3-.3-.3c-.4-.4-1-.4-1.4 0-.4.4-.4 1 0 1.4l.3.3-.3.3c-.4.4-.4 1 0 1.4zm6 0c.4.4 1 .4 1.4 0l.3-.3.3.3c.4.4 1 .4 1.4 0 .4-.4.4-1 0-1.4l-.3-.3.3-.3c.4-.4.4-1 0-1.4-.4-.4-1-.4-1.4 0l-.3.3-.3-.3c-.4-.4-1-.4-1.4 0-.4.4-.4 1 0 1.4l.3.3-.3.3c-.4.4-.4 1 0 1.4zm6 0c.4.4 1 .4 1.4 0l.3-.3.3.3c.4.4 1 .4 1.4 0 .4-.4.4-1 0-1.4l-.3-.3.3-.3c.4-.4.4-1 0-1.4-.4-.4-1-.4-1.4 0l-.3.3-.3-.3c-.4-.4-1-.4-1.4 0-.4.4-.4 1 0 1.4l.3.3-.3.3c-.4.4-.4 1 0 1.4z"></path></svg>
            </div>
            <div className='flex gap-x-2 sm:gap-x-3 hidden sm:flex' title="2FA">
                {/* License - https://iconscout.com/free-icon/password-5579228 by: Kazi Aftabur Rahman on IconScout*/}
                <svg xmlns="http://www.w3.org/2000/svg" width={props.size_standard} height={props.size_standard} viewBox="16 0 64 64" id="password"><path fill="#ffffff" d="M28.1 7c-.6 0-1 .4-1 1s.4 1 1 1h7.8c.6 0 1-.4 1-1s-.4-1-1-1h-7.8zm5.8 50c.6 0 1-.4 1-1s-.4-1-1-1H30c-.6 0-1 .4-1 1s.4 1 1 1h3.9z"></path><path fill="#ffffff" d="M48 36c-.6 0-1 .4-1 1v18.1c0 2.1-1.6 3.9-3.8 3.9H21.8c-2.1 0-3.8-1.8-3.8-3.9V8.9C18 6.8 19.6 5 21.8 5h21.5C45.4 5 47 6.8 47 8.9V19c0 .6.4 1 1 1s1-.4 1-1V8.9C49 5.7 46.5 3 43.2 3H21.8C18.5 3 16 5.7 16 8.9v46.2c0 3.2 2.5 5.9 5.8 5.9h21.5c3.2 0 5.8-2.7 5.8-5.9V37c-.1-.6-.5-1-1.1-1z"></path><path fill="#ffffff" d="M53 23H25c-.6 0-1 .4-1 1v8c0 .6.4 1 1 1h28c.6 0 1-.4 1-1v-8c0-.6-.4-1-1-1zm-1 8H26v-6h26v6z"></path><path fill="#ffffff" d="M28.3 29.7c.4.4 1 .4 1.4 0l.3-.3.3.3c.4.4 1 .4 1.4 0 .4-.4.4-1 0-1.4l-.3-.3.3-.3c.4-.4.4-1 0-1.4-.4-.4-1-.4-1.4 0l-.3.3-.3-.3c-.4-.4-1-.4-1.4 0-.4.4-.4 1 0 1.4l.3.3-.3.3c-.4.4-.4 1 0 1.4zm6 0c.4.4 1 .4 1.4 0l.3-.3.3.3c.4.4 1 .4 1.4 0 .4-.4.4-1 0-1.4l-.3-.3.3-.3c.4-.4.4-1 0-1.4-.4-.4-1-.4-1.4 0l-.3.3-.3-.3c-.4-.4-1-.4-1.4 0-.4.4-.4 1 0 1.4l.3.3-.3.3c-.4.4-.4 1 0 1.4zm6 0c.4.4 1 .4 1.4 0l.3-.3.3.3c.4.4 1 .4 1.4 0 .4-.4.4-1 0-1.4l-.3-.3.3-.3c.4-.4.4-1 0-1.4-.4-.4-1-.4-1.4 0l-.3.3-.3-.3c-.4-.4-1-.4-1.4 0-.4.4-.4 1 0 1.4l.3.3-.3.3c-.4.4-.4 1 0 1.4zm6 0c.4.4 1 .4 1.4 0l.3-.3.3.3c.4.4 1 .4 1.4 0 .4-.4.4-1 0-1.4l-.3-.3.3-.3c.4-.4.4-1 0-1.4-.4-.4-1-.4-1.4 0l-.3.3-.3-.3c-.4-.4-1-.4-1.4 0-.4.4-.4 1 0 1.4l.3.3-.3.3c-.4.4-.4 1 0 1.4z"></path></svg>
            </div>
        </div>
    )
}
